import React from "react";
import SwitchSelector from "react-switch-selector";

interface Props {
    onChange:any;
    options:any;
    initialSelectedIndex:any;
    // isOpen: boolean; 
    // if you want isOpen props to be optional
    // isOpen?: boolean;
}


const Tabs:React.FC<Props> = ({onChange,options,initialSelectedIndex}) => {
    
    // const onChange = () => {
    //     console.log();
    // };

    // const initialSelectedIndex = options.findIndex(({value}) => value === "bar");

    return (
        <div className="your-required-wrapper" style={{width: 120, height: 30}}>
        <SwitchSelector
            optionBorderRadius={0}
            selectionIndicatorMargin={0}
            onChange={onChange}
            options={options}
            initialSelectedIndex={initialSelectedIndex}
            backgroundColor={"#FF5722"}
            fontColor={"#FFFFFF"}
        />
    </div>
    );
}
export default Tabs;